<template>
  <div>
    <el-dialog
      title="安装位置"
      :visible.sync="visible"
      width="70%"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      v-loading="loading"
      custom-class="install-scene-dialog"
    >
      <el-button type="primary" style="margin-bottom:10px" @click="handleAdd"
        >新增</el-button
      >
      <el-table
        border
        :data="tableData"
        highlight-current-row
        ref="selectTable"
        height="350px"
      >
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="120">
          <template slot-scope="scope">
            <e6-td-operate
              :data="getOperateList(scope.row)"
              @command="handleOperate($event, scope.row)"
            ></e6-td-operate>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="pageSize"
          :current-page.sync="pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
    </el-dialog>
    <el-dialog
      title="新增"
      :visible="addVisible"
      width="50%"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="close"
      v-loading="editLoading"
      custom-class="add-position-dialog"
    >
      <el-form
        ref="addPositonForm"
        label-width="100px"
        :model="addPositonForm"
        :rules="rules"
        :inline="true"
      >
        <el-form-item label="安装位置" prop="processName">
          <el-input
            v-model="addPositonForm.processName"
            placeholder="安装位置"
            title="安装位置"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model.trim="addPositonForm.remark"
            placeholder="备注"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 6 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作人">
          <el-input
            v-model="userName"
            placeholder="操作人"
            title="操作人"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="操作时间">
          <el-input
            v-model="nowTime"
            placeholder="操作时间"
            title="操作时间"
            disabled
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="close">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getInstallProcess, deleteInstallProcess, saveOrUpdate } from "@/api";
import Cookies from "js-cookie";
export default {
  name: "tableDialog",
  components: {},
  data() {
    return {
      tableData: [],
      total: 0,
      pageSize: 20,
      pageIndex: 1,
      columnData: [
        {
          fieldName: "processName",
          display: true,
          fieldLabel: "安装位置",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 200,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "operationUserName",
          display: true,
          fieldLabel: "操作人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "operationTimeStr",
          display: true,
          fieldLabel: "操作时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      userName: Cookies.get("userName"),
      nowTime: new Date().toLocaleString(),
      addPositonForm: {
        processName: "",
        remark: ""
      },
      editLoading: false,
      addVisible: false,
      rules: {
        processName: [
          {
            required: true,
            message: "请输入安装位置名称",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: {
    tableVisible: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base, listPage, listPageReszie],
  computed: {
    visible: {
      get() {
        if (this.tableVisible) {
          this.queryDetailList();
        }
        return this.tableVisible;
      },
      set() {
        this.$emit("close");
      }
    }
  },
  watch: {},
  methods: {
    handleAdd() {
      this.addVisible = true;
    },
    handleClose() {
      this.visible = false;
    },
    //修改每页显示条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.queryDetailList();
    },
    //修改当前页码
    handleCurrentChange(page) {
      this.pageIndex = page;
      this.queryDetailList();
    },
    //获取列表数据 这里只写了分页的 非分页的可自行在组件内覆盖
    async queryDetailList() {
      try {
        this.loading = true;
        let res = await getInstallProcess({
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        });
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.elementTableDoLayout();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        },
        {
          label: "删除",
          id: 2
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.addPositonForm.processName = row.processName;
        this.addPositonForm.remark = row.remark;
        this.addPositonForm.installProcessId = row.installProcessId;
        this.addVisible = true;
      }
      if (val.id == 2) {
        this.$confirm("是否确认删除？", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.deleteReq(row.installProcessId);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      }
      this.clickId = row.productId;
    },
    //删除安装位置
    async deleteReq(id) {
      try {
        this.loading = true;
        let res = await deleteInstallProcess({ id });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.queryDetailList();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    handleSubmit() {
      this.$refs.addPositonForm.validate(valid => {
        if (valid) {
          this.editLoading = true;
          this.saveOrUpdateReq();
        }
      });
    },
    async saveOrUpdateReq() {
      try {
        let res = await saveOrUpdate(this.addPositonForm);
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.close();
          this.queryDetailList();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.editLoading = false;
      }
    },
    close() {
      this.addVisible = false;
      this.addPositonForm = {
        processName: "",
        remark: ""
      };
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.install-scene-dialog .el-dialog__body {
  padding: 15px;
  box-sizing: border-box;
  .table-title {
    margin-bottom: 15px;
  }
}
/deep/.add-position-dialog .el-dialog__body {
  padding: 15px;
  box-sizing: border-box;
  .el-input {
    width: 280px;
  }
  .el-textarea {
    width: 280px;
  }
}
</style>
